.panel {
  display: flex;
  .panelControls {
    width: 60px;
    min-width: 60px;
    height: 100%;
    background-color: #307FF4;
    display: flex;
    flex-direction: column;
    .link {
      display: block;
      height: 36px;
      margin: 22px 12px;
      .add {
        border-radius: 50%;
        transition: all 200ms ease-in-out; } }
    .containerTabsPills {
      flex-grow: 1; } }

  .panelContent {
    width: 297px;
    min-width: 297px; }

  .radio {
    list-style: none;
    padding: 0;
    & li:nth-of-type(n+3) label {
      padding-right: 10px; }
    label {
      cursor: pointer;
      padding: 12px;
      margin: 0;

      & .icon {
        stroke: #174FA3;
        transition: all 100ms ease-in-out; }

      &:hover .icon {
        stroke: #307FF4; }
      &:hover .saveRecalc-arrows {
        fill: #307FF4; }
      &:disabled {
        opacity: 0.2;
        cursor: default;
        &:hover .icon {
          stroke: #174FA3; }
        &:hover .saveRecalc-arrows {
          fill: #174FA3; } }
      &:focus {
        outline: none; } }

    input {
      display: none;

      &:checked + label {
        background-color: #174FA3; }
      &:checked + label .icon {
        stroke: #307FF4; }
      &:checked + label .saveRecalc-arrows {
        fill: #307FF4; }
      &:disabled + label {
        opacity: 0.2;
        cursor: default; } }

    button {
      padding: 12px;
      background-repeat: no-repeat;
      background-position: center center;
      background-color: #333333;
      cursor: pointer;
      border-radius: 0 !important;
      border: none;

      & .icon {
        stroke: #174FA3;
        transition: all 100ms ease-in-out; }

      &:focus .icon {
        stroke: #307FF4; }
      &:focus .saveRecalc-arrows {
        fill: #307FF4; }
      &:hover .icon {
        stroke: #307FF4; }
      &:hover .saveRecalc-arrows {
        fill: #307FF4; }
      &:disabled {
        opacity: 0.2;
        cursor: default;
        &:hover .icon {
          stroke: #174FA3; }
        &:hover .saveRecalc-arrows {
          fill: #174FA3; } }
      &:focus {
        outline: none; } }

    .concept {
      border-radius: 50%;
      transition: all 200ms ease-in-out; } } }
