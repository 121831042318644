header.header {
  padding: 0 15px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: white;

  a {
    height: 60px;
    line-height: 60px;
    padding-right: 15px;
    border-right: 1px solid #333333;
    // margin-right: 44px

    img {
      width: 140px; } }
  .breadCrumbs {
    display: flex;
    list-style: none;
    align-items: center;
    margin: 0;
    padding: 0;
    li {
      a {
        border: none;
        font-size: 1rem;
        color: #333333;
        padding: 0 2px 0 2px; }

      span {
        display: block;
        line-height: 20px;
        margin: 0;
        padding: 0 2px 0 2px;
        height: 19px;
        color: #333333;
        border: none;
        font-size: 1rem; } } } }

.project {
  width: 100%;
  padding: 0 30px 0 40px;
  display: flex;
  align-items: center;
  h5.title {
    margin-bottom: 0;
    margin-right: 73px;
    color: #333333;
    font-family: Lato;
    font-size: 20px;
    font-weight: 700; }

  &>p {
    margin-right: 63px;
    color: #333333;
    font-family: Lato;
    font-size: 16px;
    font-weight: 700; }

  .location {
    flex-grow: 1;
    margin-right: 50px;
    font-family: Lato;
    font-size: 16px; }

  svg.icon {
    height: 15px;
    width: 11px;
    fill: #307FF4;
    margin-right: 10px; }
  .gray {
    color: #333333; }
  .logoOfHotel {
    height: 30px;
    width: auto;
    margin-right: 30px; }
  .supportLink {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 3px solid #333333;
    color: #333333;
    padding-right: 0;
    line-height: 24px;
    font-size: 22px;
    font-weight: 700;
    &:hover {
      text-decoration: none; } } }
.logOutButton {
  color: #E5E5E5; }
.profile {
  padding-left: 30px;
  border-left: 1px solid #333333;
  height: 100%;
  display: flex;
  align-items: center;

  span.more {
    height: 100%;
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding-left: 20px;
    .userAvatar {
      width: 100%;
      height: 40px;
      object-fit: contain;
      border-radius: 50%; }
    .icon, .iconMenu {
      margin-left: 15px;
      fill: #333333; }
    &:hover .iconMenu {
      fill: #307FF4; }

    .moreControls {
      width: 200px;
      position: absolute;
      background: white;
      text-align: left;
      border: 1px solid gainsboro;
      z-index: 11;
      top: 30px;
      right: -10px;


      div {
        height: 40px;
        display: flex;
        align-items: center;
        transition: all 200ms ease-in-out;

        &:hover {
          background-color: #333333;
          color: white;
          .icon {
            fill: #ffffff; }
          .QuestionIcon {
            color: #ffffff;
            border: 1px solid #ffffff; } }

        svg {
          width: 24px;
          height: 24px;
          margin: 0 10px;
          // fill: #ffffff
          .icon {
            fill: #333333; } }

        .QuestionIcon {
          display: inline-block;
          width: 24px;
          height: 24px;
          margin: 0 10px;
          border-radius: 50%;
          border: 1px solid #333333;
          color: #333333;
          padding-right: 0;
          line-height: 20px;
          font-size: 16px;
          font-weight: 400;
          text-align: center;
          cursor: pointer; }
        &:not(:last-child) {
          border-bottom: 1px solid rgba(34,35,42,0.13); } } } } }
