.steps {
  min-height: 50px;

  .step {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: relative;

    .top {
      display: flex;
      align-items: center; }

    .number {
      width: 25px;
      height: 25px;
      background: white;
      border-radius: 50%;
      transition: all 1s linear;

      &.finished {
        background: #307FF4;
        color: white;
        transition: all 1s linear; } }

    .title {
      position: absolute;
      top: 25px; } }

  .line {
    width: 85px;
    height: 2px;
    position: relative;
    top: 12px;
    background: white;
    transition: all 1s linear;

    &.finished {
      transition: all 1s linear;
      background: #307FF4; } } }
