form.setBack {
  .cont {
 }    // height: calc(100% - 120px)
  .containerOfcontrols {
    // height: calc(100% - 135px)
    height: 300px;
    position: relative;
    @media screen and (min-width: 1900px) {
      height: 425px; }
    &>span {
      top: -53px;
      padding-right: 20px; } }
  .sides {
    // height: 40%
    padding: 20px;
    height: 100%;
    overflow-x: auto;
    .sides__headline {
      display: flex;
      justify-content: space-between; }
    .sides__line {
      display: flex;
      justify-content: space-between; }
    h6 {
      margin-bottom: 15px;
      font-weight: 700; }
    h6:nth-of-type(2) {
      width: 265px;
      text-align: center; }
    h6:nth-of-type(3) {
      width: 100px;
      text-align: right; }

    p {
      margin: 0 0 13px 0;
      line-height: 32px;
      font-weight: bold;
      width: 37px; }

    .radio {
      margin-bottom: 13px;

      label {
        margin: 0;
        height: 32px;
        width: 105px;
        font-size: 14px;
        line-height: 32px;
        text-align: center;
        background: #E5E5E5;
        cursor: pointer;

        @media screen and (min-width: 1540px) {
          width: 105px; }
        @media screen and (min-width: 1900px) {
          width: 140px; }

        &:first-of-type {
          border-top-left-radius: 20px;
          border-bottom-left-radius: 20px; }

        &:nth-of-type(2) {
          margin: 0 5px; }

        &:last-of-type {
          border-top-right-radius: 20px;
          border-bottom-right-radius: 20px; } }

      input {
        display: none;

        &:checked + label {
          background: #307FF4;
          color: white; } } }

    input.setback {
      height: 32px;
      width: 114px;
      background-color: #ff0000;
      line-height: 32px;
      margin-bottom: 13px;
      margin-right: 5px;
      text-indent: 10px;
      border: none;
      outline: none;
      background: #F4F7FC; } } }

.text {
  text-align: left;

  h5.title {
    color: #BDBFC6;
    font-size: 14px; }

  h2 {
    font-weight: 600;
    font-size: 26px; }

  h4.title {
    font-size: 16px;
    font-weight: 500;
    font-weight: 500;
    color: #979797; }

  input {
    width: 100%;
    border: none;
    border-bottom: 1px solid #BDBFC6;
    outline: none;

    &.error {
      border: 1px solid red;
      text-indent: 10px; } }

  .inputContainer {
    position: relative;
    input {
      margin: 0 0 15px 0;
      display: block;
      width: 70px;
      padding: 5px 22px 5px 10px;
      color: #00A2E8;
      font-family: Lato;
      font-size: 15px;
      line-height: 20px;
      text-align: right;
      background-color: #F4F7FC;
      border: none;
      font-weight: 700;
      @media screen and (min-width: 1900px) {
        width: 100px; } }
    input.inputFT {
      padding-right: 22px; }
    label.inputSuffixFT {
      position: absolute;
      top: 5px;
      right: 8px;
      color: #00A2E8;
      font-family: Lato;
      font-size: 15px;
      line-height: 20px;
      font-weight: 400;
      user-select: none; } }

  .controls {
    position: absolute;
    right: 30px;
    bottom: 35px;

    button {
      outline: none;
      border: none;
      cursor: pointer;

      &.next {
        width: 130px;
        height: 40px;
        border-radius: 3px;
        background-color: #307FF4;
        color: white;
        font-weight: 600;

        &:after {
          content: '';
          background-image: url("../../../../assets/img/icons/next.png");
          width: 10px;
          height: 10px;
          background-repeat: no-repeat;
          display: inline-block;
          position: relative;
          background-size: contain;
          left: 5px; } }

      &.prev {
        margin-right: 20px;
        height: 40px;
        width: 80px;
        color: #9A9CA5;
        background: transparent;
        font-weight: 600;

        &:before {
          content: '';
          background-image: url("../../../../assets/img/icons/prev.png");
          width: 10px;
          height: 10px;
          background-repeat: no-repeat;
          display: inline-block;
          position: relative;
          background-size: contain;
          right: 5px; } } } } }
