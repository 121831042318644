.revenue {
  margin: 20px 0 0 20px;
  .blockPanel {
    background: white;
    text-align: left;
    padding: 20px;
    position: relative;
    margin-bottom: 5px;
    margin-right: 10px;

    .infoMessage {
      font-size: 12px;
      color: #7f7f7f;
      background-color: #fdf2ea;
      padding: 10px;
      span {
        font-weight: 900; } }

    h3.title {
      color: #333333;
      font-family: Lato;
      font-size: 16px;
      font-weight: 900;
      line-height: 20px;
      text-transform: capitalize;
      margin-bottom: 25px; }

    h5.name {
      color: #333333;
      font-family: Lato;
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;
      text-transform: capitalize; }

    button.customRevenueLine {
      margin-bottom: 8px;
      padding: 5px;
      display: flex;
      align-items: center;
      width: 100%;
      border: 1px dashed #307FF4;
      border-radius: 5px;
      background-color: #ffffff;
      cursor: pointer;
      &:focus {
        outline: none; }
      p {
        margin: 0 auto;
        font-size: 14px; }
      .add {
        width: 18px;
        height: 18px;
        fill: #307FF4;
        stroke: #307FF4; } }

    .textInput {
      margin-bottom: 15px;
      display: flex;
      justify-content: space-between;
      .titleOfInput {
        width: 95px; }
      .textInput_fix {
        display: flex;
        flex-direction: column;
        justify-content: flex-end; }
      .inputContainer {
        position: relative; }
      .inputSuffix {
        position: absolute;
        top: 5px;
        left: 110px;
        color: #00A2E8;
        font-family: Lato;
        font-size: 15px;
        line-height: 20px;
        font-weight: 400;
        user-select: none; }
      .MeetingRooms {
        padding-right: 42px;
        padding-left: 10px;
        width: 150px; }
      .inputPercent {
        padding-right: 23px; }
      .inputSuffixPercent {
        position: absolute;
        top: 5px;
        right: 11px;
        color: #00A2E8;
        font-family: Lato;
        font-size: 15px;
        line-height: 20px;
        font-weight: 400;
        user-select: none; }
      input.CustomInputValue {
        width: 125px;
        padding: 5px 10px;
        &:focus {
          outline: none; } }
      input.CustomInputLabel {
        width: 180px;
        padding: 5px 10px;
        text-align: left;
        &:focus {
          outline: none; } }
      button.CustomButtonClose {
        padding: 0;
        margin-right: 5px;
        background-color: transparent;
        border: none;
        height: fit-content;
        fill: #307FF4;
        cursor: pointer;
        &:focus {
          outline: none; } }

      label {
        margin: 0 0 5px 0;
        color: #333333;
        font-family: Lato;
        font-size: 14px;
        font-weight: 700;
        line-height: 20px; }

      input, select {
        display: block;
        width: 95px;
        padding: 5px 10px;
        color: #00A2E8;
        font-family: Lato;
        font-weight: 700;
        font-size: 15px;
        line-height: 20px;
        text-align: right;
        background-color: #F4F7FC;
        border: none; }
      select {
        color: #333333;
        font-weight: 400; } }

    .radio {
      margin-bottom: 13px;

      label {
        margin: 0;
        height: 24px;
        width: 100%;
        font-size: 12px;
        font-weight: 700;
        line-height: 24px;
        text-align: center;
        background: #E5E5E5;
        cursor: pointer;
        text-transform: none;
        transition: all 200ms ease-in-out;
        border-radius: 20px;

        &:not(:last-child) {
          margin-bottom: 10px; } }


      input {
        display: none;

        &:checked + label {
          background: #307FF4;
          color: #FFFFFF; } } }

    .radioBlended {
      margin-bottom: 13px;
      &>div {
        margin-left: 10px; }

      label {
        margin: 0;
        height: 24px;
        width: 95px;
        font-size: 12px;
        font-weight: 700;
        line-height: 24px;
        text-align: center;
        background: #E5E5E5;
        cursor: pointer;
        text-transform: capitalize;
        transition: all 200ms ease-in-out;

        &:first-of-type {
          border-top-left-radius: 20px;
          border-bottom-left-radius: 20px;
          margin-right: 5px; }

        &:last-of-type {
          border-top-right-radius: 20px;
          border-bottom-right-radius: 20px; } }

      input {
        display: none;

        &:checked + label {
          background: #307FF4;
          color: #FFFFFF; } } } } }

.submit {
  border: none;
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: transparent;
  cursor: pointer;
  padding: 0;
  font-size: 5px;
  &:focus {
    outline: none; }
  .roundArrow {
    fill: rgb(68, 77, 111);
    transition: all 200ms ease-in-out;
    &:hover, &:focus {
      fill: #307FF4;
      transform: rotate(25deg); }
    &:active {
      fill: #ff0000; } } }
