.budget {
  margin: 20px 0 0 20px;
  height: calc(100vh - 92px);
  // position: relative
  // overflow-y: scroll
  // &::-webkit-scrollbar
  //   width: 0px
  .blockPanel {
    background: white;
    text-align: left;
    padding: 20px;
    position: relative;
    margin-bottom: 5px;
    margin-right: 10px;

    h3.title {
      color: #333333;
      font-family: Lato;
      font-size: 16px;
      font-weight: 900;
      line-height: 20px;
      text-transform: capitalize;
      margin-bottom: 25px; }

    h5.name {
      margin-bottom: 5px;
      color: #333333;
      font-family: Lato;
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;
      text-transform: capitalize; }

    button.customBudgetLine {
      margin-bottom: 8px;
      padding: 5px;
      display: flex;
      align-items: center;
      width: 100%;
      border: 1px dashed #307FF4;
      border-radius: 5px;
      background-color: #ffffff;
      cursor: pointer;
      &:focus {
        outline: none; }
      p {
        margin: 0 auto;
        font-size: 14px; }
      .add {
        width: 18px;
        height: 18px;
        fill: #307FF4;
        stroke: #307FF4; } }

    .textInput {
      display: flex;
      justify-content: space-between;
      .inputContainer {
        position: relative; }
      input.inputPercent {
        padding-right: 22px; }
      label.inputSuffixPercent {
        position: absolute;
        top: 5px;
        right: 10px;
        color: #00A2E8;
        font-family: Lato;
        font-size: 15px;
        line-height: 20px;
        font-weight: 400;
        user-select: none; }
      label.inputSuffix {
        width: 45px;
        position: absolute;
        top: 5px;
        right: -10px;
        color: #00A2E8;
        font-family: Lato;
        font-size: 15px;
        line-height: 20px;
        font-weight: 400;
        user-select: none; }
      input.BudgetHard {
        width: 125px;
        padding-right: 35px;
        padding-left: 10px; }
      input.CustomInputValue {
        width: 125px;
        padding: 5px 10px;
        &:focus {
          outline: none; } }
      input.CustomInputLabel {
        width: 180px;
        padding: 5px 10px;
        text-align: left;
        &:focus {
          outline: none; } }
      button.CustomButtonClose {
        padding: 0;
        margin-right: 5px;
        background-color: transparent;
        border: none;
        height: fit-content;
        fill: #307FF4;
        cursor: pointer;
        &:focus {
          outline: none; } }

      label {
        margin: 0 0 5px 0;
        color: #333333;
        font-family: Lato;
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
 }        // text-transform: capitalize

      input, select {
        margin: 0 0 15px 0;
        display: block;
        width: 95px;
        padding: 5px 10px;
        color: #00A2E8;
        font-family: Lato;
        font-size: 15px;
        line-height: 20px;
        text-align: right;
        background-color: #F4F7FC;
        border: none;
        font-weight: 700; }
      select {
        color: #333333;
        font-weight: 400; }
      span {
        width: 115px;
        color: #333333;
        font-family: Lato;
        font-size: 12px;
        font-weight: 700;
        line-height: 30px; } }

    .radio {
      margin-bottom: 13px;

      label {
        margin: 0;
        height: 24px;
        width: 100%;
        font-size: 12px;
        font-weight: 700;
        line-height: 24px;
        text-align: center;
        background: #E5E5E5;
        cursor: pointer;
        text-transform: none;
        transition: all 200ms ease-in-out;

        &:first-of-type {
          border-radius: 20px;
          margin-right: 5px;
          margin-bottom: 10px; }

        &:last-of-type {
          border-radius: 20px; } }

      input {
        display: none;

        &:checked + label {
          background: #307FF4;
          color: #FFFFFF; } } }

    .textInputColumn {
      margin-bottom: 15px;
      .inputAcquisitionContainer {
        display: flex;
        align-items: flex-start; }
      input.Acquisition {
        width: 125px; }

      label {
        display: block;
        margin: 0 0 5px 0;
        color: #333333;
        font-family: Lato;
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
        text-transform: capitalize; }
      ul {
        list-style: none;
        padding: 0;
        margin: 0;
        display: inline-block;
        li {
          color: #333333;
          font-family: Lato;
          font-size: 15px;
          font-weight: 700; } }

      input, select {
        margin: 0 15px 15px 0;
        display: inline-block;
        width: 50%;
        padding: 5px 10px;
        color: #00A2E8;
        font-family: Lato;
        font-size: 15px;
        line-height: 20px;
        text-align: right;
        background-color: #F4F7FC;
        border: none;
        font-weight: 700; }
      .key {
        color: #333333; }
      select {
        color: #333333; } } } }

.submit {
  border: none;
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: transparent;
  cursor: pointer;
  padding: 0;
  font-size: 5px;
  &:focus {
    outline: none; }
  .roundArrow {
    fill: rgb(68, 77, 111);
    transition: all 200ms ease-in-out;
    &:hover, &:focus {
      fill: #307FF4;
      transform: rotate(25deg); }
    &:active {
      fill: #ff0000; } } }
