section {
  ul {
    button {
      &:focus .saveRecalc-arrows {
        fill: #307FF4; }
      &:hover .saveRecalc-arrows {
        fill: #307FF4; }
      &:disabled {
        &:hover .saveRecalc-arrows {
          fill: #174FA3; } } } } }
