.tooltipContent {
  background-color: #ffffff !important;
  border: 1px solid #333333 !important;
  width: 236px !important;
  padding: 10px !important;
  text-transform: none !important;
  border-radius: 3px !important;
  font-weight: 400 !important;
  opacity: 1 !important;
  &:after {
    // border-top-color: #333333 !important
    // border-top-style: solid !important
    // border-top-width: 6px !important
    border: none !important; }

  .tooltipTitle {
    text-transform: capitalize !important;
    font-weight: 700 !important; } }

.tooltipIcon {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #333333;
  color: #333333;
  padding-right: 0;
  line-height: 16px;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  cursor: pointer; }
