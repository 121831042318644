section.concept {
  //min-height: 1000px
  //height: 100vh
  margin: 20px 0 0 20px;
  // min-height: calc(100vh - 76px)
  .loaderProgram {
    width: 100%;
    height: 80%;
    z-index: 1000000;
    display: flex;
    justify-content: center;
    align-items: center;
 }    // background-color: rgba(0, 0, 0, 0.3)
  .sort-panel {
    margin-right: 10px;
    padding: 10px;
    font-size: 14px;
    font-weight: 700;
    color: #333333;
    background-color: #F4F7FC;
    border-bottom: 5px solid #307FF4;
    p {
      display: flex;
      justify-content: space-between;
      margin: 0;
      &:first-child {
        margin-bottom: 5px; } }

    label {
      height: 25px;
      width: 75px;
      padding: 0;
      margin: 0;
      display: block;
      border: none;
      font-size: 12px;
      font-weight: 700;
      color: #307ff4;
      background-color: #E7EEF9;
      border-radius: 25px;
      cursor: pointer;
      transition: all 200ms ease-in-out;
      line-height: 25px;
      &:focus {
        outline: none; }
      &:hover {
        background-color: #307ff4;
        color: #ffffff; } }
    input {
      display: none;

      &:checked + label {
        background-color: #307FF4;
        color: #FFFFFF; } } }

  .cardContainer {
    position: relative;

    .option {
      background: #ffffff;
      transition: all 0.3s linear;
      margin-right: 10px;
      position: relative;
      font-size: 12px;
      font-weight: 700;
      color: #333333;
      .boldStyleForSort {
        font-weight: 900;
        color: #333333; }

      &:hover {
        transition: all 0.3s linear;
        cursor: pointer;
        .firstRow .buttonContainer .PDFDownloadLink {
          opacity: 1;
          stroke: #333333;
          fill: #333333; }

        .firstRow .buttonContainer button {
          opacity: 1;
          stroke: #333333;
          fill: #333333; }

        &::after {
          border: 2px solid #307FF4;
          content: "";
          width: 100%;
          height: 100%;
          background-color: rgba(48, 127, 244, 0.09);
          position: absolute;
          top: 0;
          right: 0; } }

      .info>h5 {
        overflow: auto; }
      .firstRow {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 32px;
        padding: 0px 8px;
        .buttonContainer {
          display: flex;
          align-items: center;
          button {
            position: relative;
            z-index: 1;
            padding: 1;
            margin: 0 5px;
            border: none;
            font-size: 10px;
            line-height: 10px;
            background-color: transparent;
            opacity: 0;
            &:hover {
              .iconStar {
                stroke: #ffc000;
                transform: scale(1.3); }
              .iconPDF {
                stroke: #307FF4;
                transform: scale(1.3); }
              .iconHidden {
                stroke: #ff0000;
                transform: scale(1.3); } }
            &:focus {
              outline: none; }
            .iconStar, .iconHidden, .iconPDF {
              width: 16px;
              height: 16px;
              cursor: pointer;
              transition: all 0.2s linear;
              stroke: #4F4F4F; } } } }

      .secondRow {
        background-color: #F4F7FC;
        padding: 0 8px; }

      .thirdRow {
        padding: 0 8px; }

      h5, span {
        font-size: 12px;
        color: #333333;
        font-weight: 700; }

      h5 {
        font-weight: 700;
        color: #333333;
        text-align: left; }

      img.concept-img {
        width: 110px;
        height: 104px;
        padding-bottom: 1px; }
      .info {
        display: flex;
        justify-content: space-around;
        width: 100%; }

      .keys, .budget, .investment {
        line-height: 0; } }

    .active {
      transition: all 0.3s linear;
      &::after {
        border: 2px solid #307FF4;
        content: "";
        width: 100%;
        height: 100%;
        background-color: rgba(48, 127, 244, 0.09);
        position: absolute;
        top: 0;
        right: 0; } }

    .confirmHidingOption {
      display: flex;
      padding: 8px 16px;
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      width: 96.5%;
      height: 100%;
      background-color: #E5E5E5;
      color: #333333;
      font-size: 14px;
      font-weight: 700;
      text-align: left;
      .iconDangerSign {
        width: 30px;
        height: 30px;
        background-color: red;
        border-radius: 50%;
        fill: #ffffff;
        padding: 3px;
        margin: 7px 15px 0 0; }
      .confirmMessage {
        .confirmHead {
          margin-bottom: 4px; }
        .confirmText {
          font-size: 12px;
          margin-bottom: 8px; }
        .confirmButtons {
          margin-bottom: 0px;
          text-align: right;
          button {
            width: 80px;
            height: 25px;
            border: none;
            border-radius: 25px;
            background-color: #333333;
            margin-right: 4px;
            &:focus {
              outline: none;
              background-color: #307FF4;
              color: #FFFFFF; }
            &:hover {
              background-color: #307FF4;
              color: #FFFFFF; } } } } }

    .loadingPDF {
      display: flex;
      padding: 8px 16px;
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      width: 96.5%;
      height: 100%;
      background-color: #E5E5E5;
      color: #333333;
      font-size: 14px;
      font-weight: 700;
      text-align: left;
      &__icon {
        width: 100%;
        height: 100%;
        z-index: 1000000;
        display: flex;
        justify-content: center;
        align-items: center;
        &>div {
          width: 22px!important;
          height: 22px!important; } }
      &__message {
        display: flex;
        align-items: center; }
      &__text {
        margin: 0; } } } }
