.tab {
    font-family: 'Lato', sans-serif;
    font-size: 14px;
    letter-spacing: 0.5px;
    line-height: 20px;
    color: #FFFFFF;
    display: flex;
    background-color: #F4F7FC;
    height: 100%;
    padding: 6px 8px;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    border: 0;
    border-top: 4px solid transparent;
    border-bottom: 5px solid #307FF4;

    outline: 0;
    cursor: pointer;
    transition: all 200ms ease-in-out;
    &:focus {
        outline: none; }
    &:hover {
        background-color: #307FF4;
        color: #FFFFFF;
        p.head {
            color: #FFFFFF; }
        div {
            p.value {
                color: #FFFFFF;
                opacity: 0.9; } } }

    p.head {
        margin-bottom: 0;
        font-size: 18px;
        font-weight: 700;
        color: #333333;
        text-transform: capitalize;
        img.icon {
            height: 40px;
            width: 40px;
            margin-right: 12px;
            background-color: #ffffff;
            border-radius: 50%; } }
    div {
        p.value {
            margin-bottom: 0;
            opacity: 0.7;
            color: #333333;
            font-weight: 900;
            text-align: right; } } }
.active {
    background-color: #307FF4;
    color: #FFFFFF;
    p.head {
        color: #FFFFFF; }
    div {
        p.value {
            color: #FFFFFF;
            opacity: 0.9; } } }
