.dashboard {
  min-height: 100vh;
  background: #E5E5E5;

  .main {
    min-height: calc(100vh - 60px);
    display: flex; }
  .loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1000000;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.3); } }
