.buildingProgram {
  margin: 20px 0 0 20px;
  .form {
    background: white;
    text-align: left;
    padding: 20px;
    position: relative;
    margin-right: 10px;
    margin-bottom: 5px;

    .error {
      position: absolute;
      color: red;
      font-size: 14px; }

    h3.title {
      color: #333333;
      font-family: Lato;
      font-size: 16px;
      font-weight: 900;
      line-height: 20px;
      text-transform: capitalize;
      margin-bottom: 25px; }

    h5.name {
      color: #333333;
      font-family: Lato;
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;
      text-transform: capitalize; }
    div.textInput {
      display: flex;
      justify-content: space-between;
      .inputContainer {
        position: relative; }
      input.inputPercent {
        padding-right: 22px; }
      label.inputSuffixPercent {
        position: absolute;
        top: 5px;
        right: 10px;
        color: #00A2E8;
        font-family: Lato;
        font-size: 15px;
        line-height: 20px;
        font-weight: 400;
        user-select: none; }

      label {
        margin: 0 0 5px 0;
        color: #333333;
        font-family: Lato;
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
 }        // text-transform: capitalize

      input, select {
        margin: 0 0 7px 0;
        display: block;
        width: 95px;
        padding: 5px 10px;
        color: #00A2E8;
        font-family: Lato;
        font-size: 15px;
        line-height: 20px;
        text-align: right;
        background-color: #F4F7FC;
        border: none;
        font-weight: 700; }
      select {
        color: #333333;
        font-weight: 400; }
      span {
        width: 95px;
        color: #333333;
        font-family: Lato;
        font-size: 12px;
        font-weight: 700;
        line-height: 30px; } }

    .radio {
      margin-bottom: 13px;

      label {
        margin: 0;
        height: 24px;
        width: 102px;
        font-size: 12px;
        font-weight: 700;
        line-height: 24px;
        text-align: center;
        background: #E5E5E5;
        cursor: pointer;
        text-transform: capitalize;
        transition: all 200ms ease-in-out;

        &:first-of-type {
          border-top-left-radius: 20px;
          border-bottom-left-radius: 20px;
          margin-right: 5px; }

        &:last-of-type {
          border-top-right-radius: 20px;
          border-bottom-right-radius: 20px; } }

      input {
        display: none;

        &:checked + label {
          background: #307FF4;
          color: #FFFFFF; } } } } }
.submit {
  border: none;
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: transparent;
  cursor: pointer;
  padding: 0;
  font-size: 5px;
  &:focus {
    outline: none; }
  .roundArrow {
    fill: rgb(68, 77, 111);
    transition: all 200ms ease-in-out;
    &:hover, &:focus {
      fill: #307FF4;
      transform: rotate(25deg); }
    &:active {
      fill: #ff0000; } } }
