div.tools {
  position: absolute;
  z-index: 1;
  top: 10px;
  right: 10px;
  display: flex;
  flex-direction: column;
  // border: 2px solid #E5E5E5;
  border-radius: 5px;
}
div.tools button {
  cursor: pointer;
  // margin-right: 10px;
  background-color: transparent;
  color: #333333;
  border: none;
}
div.tools button:not(:last-of-type) {
  // border-bottom: 1px solid #E5E5E5;
}
div.tools button:hover {
  color: #307ff4;
}
