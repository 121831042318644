.sidePanel {
    margin-right: 10px;
    flex-grow: 1;
    min-width: 505px;
    &>div>:first-child {
        padding-right: 10px; } }
.columnContainer {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
    .height, .floors, .parking, .far {
        width: 24.5%;
        background-color: #ffffff;
        color: #333333;
        font-family: Lato;
        font-size: 14px;
        padding: 20px;
        display: flex;
        justify-content: center;
        @media screen and (min-width: 1750px) {
            justify-content: space-between; } }

    .bold {
        font-weight: 700;
        margin-left: 3px; } }
.hotelBrandContact {
    margin-top: 1px;
    background-color: #ffffff;
    color: #333333;
    font-family: Lato;
    font-size: 14px;
    padding: 8px 0;
    margin-bottom: 0;
    border-bottom: 1px solid #E5E5E5; }
.contactCard {
    background-color: #ffffff;
    color: #333333;
    font-family: Lato;
    font-size: 14px;
    padding: 15px 10px 10px 15px;
    display: flex;
    .avatar {
        width: 150px;
        object-fit: contain; }
    .cont {
        margin-left: 20px;
        width: 55%; }
    &__name {
        margin-top: 4px;
        margin-bottom: 3px;
        color: #333333;
        font-family: Lato;
        font-size: 16px;
        font-weight: 700;
        line-height: 20px;
        text-align: left; }
    &__occupation {
        // width: 330px //180px
        margin-bottom: 0px;
        color: #333333;
        font-family: Lato;
        font-size: 12px;
        font-weight: 700;
        line-height: 14px;
        text-align: left; }
    &__buttonsBar {
        text-align: left;
        margin-bottom: 0;
        display: flex;
        flex-direction: column; }
    & a {
        margin-right: 5px;
        border: none;
        cursor: pointer;
        color: #333333;
        &:focus {
            outline: none; }
        &:hover .icon {
            stroke: #307FF4; }
        &:hover {
            color: #307FF4;
            text-decoration: none; } }

    &__phone {
        .icon {
            width: 18px;
            height: 18px;
            stroke: #333333;
            margin-right: 5px; } }
    &__email {
        .icon {
            width: 18px;
            height: 18px;
            stroke: #333333;
            margin-right: 5px; } }

    &__logo {
        display: block;
        margin-left: auto;
        height: 45px; } }
