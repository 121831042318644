section.editUser {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;


  background-image: url("../../../assets/img/construction.png");
  background-repeat: no-repeat;
  background-position: right;
  background-size: cover;

  .wrapper {
    background: white;
    text-align: center;
    border-radius: 3px;

    h4 {
      font-size: 20px;
      font-weight: 600;

      @media screen and (min-width: 768px) {
        font-size: 28px; } }

    .email,.password, .input {
      font-size: 14px;
      .inputContainer {
        width: 300px; }
      @media screen and (min-width: 768px) {
        font-size: 16px; }

      h5 {
        width: 100px;
        text-align: left;
        font-size: 14px;
        color: #787878;
        margin: 0 20px 0 0;
        text-transform: uppercase;

        @media screen and (min-width: 576px) {
          text-align: right; } }

      input {
        width: 100%;
        height: 46px;
        border: 1px solid #333333;
        text-indent: 60px;
        border-radius: 3px;

        @media screen and (min-width: 576px) {
          width: 300px; }

        &.error {
          border: 1px solid red; } } }

    .email {
      input {
        background-image: url('../../../assets/img/icons/email.svg') !important;
        background-repeat: no-repeat;
        background-position: 25px 17px;
        background-size: 20px; } }

    .password {
      input {
        background-image: url('../../../assets/img/icons/password.svg') !important;
        background-repeat: no-repeat;
        background-position: 25px 13px;
        background-size: 20px; } }

    button.edituser {
      background: #307FF4;
      height: 46px;
      width: 300px;
      font-size: 14px;
      font-weight: 500;
      color: white;
      border-radius: 3px;
      border: none;
      outline: none;

      &:hover {
        border: 1px solid #E5E5E5;
        cursor: pointer; }
      &:disabled {
        opacity: 0.5; } }

    .navigation {
      margin-bottom: 30px;

      a:first-of-type {
        margin-right: 15px; } } } }
