.Map {
  //   min-height: 250px;
  padding: 0;
  flex-grow: 1;
  margin-top: 20px;
  margin-bottom: 5px;
  position: relative;
  background: white;
  border: none;
  transition: all 0.3s linear;
  width: 100%;
  cursor: pointer;
  .MapHead {
    padding: 8px;
    margin-bottom: 0;
    font-size: 18px;
    font-weight: 700;
    text-transform: capitalize;
    border-bottom: 5px solid #307ff4;
    background-color: #f4f7fc;
    color: #333333;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 80px;
    .MapLotArea {
      color: #333333;
      text-transform: none;
      font-size: 14px;
    }
  }
  &:focus {
    outline: none;
  }
  .toggleBtn {
    position: absolute;
    left: 3px;
    top: 5px;
    width: 130px;
    height: 40px;
    border-radius: 5px;
    background-color: #307ff4;
    color: white;
    font-weight: 600;
    outline: none;
    cursor: pointer;
    padding: 1px 7px 2px;
    font-size: 13px;
    border: none;
    outline: none !important;
  }

  .toggleBtn:disabled {
    background-color: #e5e5e5;
    opacity: 0.2;
    cursor: default;
    color: #333333;
  }
}

.mapParcel {
  height: 250px;
  position: relative;
}
.mapParcel canvas {
  position: absolute;
  left: 0;
  width: 100%;
}
.gray {
  color: #333333;
}
