.PerspectiveView {
  background-color: #ffffff;
  width: 100%;
  margin-bottom: 5px;
  color: #333333;
  font-family: Lato;
  font-size: 12px;
  border: 2px solid transparent;
  transition: all 0.3s linear;
  cursor: pointer;
}
.PerspectiveView:hover {
  border: 2px solid #307ff4;
  transition: all 0.3s linear;
}
.carousel {
  position: relative;
  height: 190px;
  overflow: hidden;
}
.ArrowLeft,
.ArrowRight,
.Icon,
.IconNumbers {
  max-width: 100%;
  max-height: 100%;
}
.IconNumbers {
  font-size: 16px;
  font-weight: 700;
  line-height: 1.3;
}
.carousel_title {
  padding: 10px 0;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  text-align: center;
  border-bottom: 2px solid #e5e5e5;
}
.carousel_slide {
  padding: 10px 45px;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  text-align: center;
  z-index: 1;
  background-color: #ffffff;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
}
.carousel_slide img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}
.carousel_control_container {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.carousel_control {
  margin: 5px;
  align-items: center;
  display: flex;
  justify-content: center;
  border: 0;
  padding: 0;
  // width: 4%;
  width: 30px;
  overflow: hidden;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  cursor: pointer;
  fill: #333333;
  color: #ffffff;
  background-color: #333333;
  height: 30px;
}
.carousel_control:focus {
  outline: none;
}
.carousel_control:hover {
  fill: #307ff4;
  background-color: #307ff4;
}
.carousel_control__active {
  fill: #307ff4;
  background-color: #307ff4;
}
.carousel_control__prev,
.carousel_control__next {
  fill: #ffffff;
  background-color: #ffffff;
}

.leave {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

.enter_active.enter_next,
.enter_active.enter_prev {
  -webkit-transform: translateX(0);
  transform: translateX(0);
  -webkit-transition: -webkit-transform 500ms linear;
  transition: -webkit-transform 500ms linear;
  transition: transform 500ms linear;
  transition: transform 500ms linear, -webkit-transform 500ms linear;
}

.enter_next {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}

.enter_prev {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  -webkit-transition: -webkit-transform 500ms linear;
  transition: -webkit-transform 500ms linear;
  transition: transform 500ms linear;
  transition: transform 500ms linear, -webkit-transform 500ms linear;
}

.leave_active_next {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  -webkit-transition: -webkit-transform 500ms linear;
  transition: -webkit-transform 500ms linear;
  transition: transform 500ms linear;
  transition: transform 500ms linear, -webkit-transform 500ms linear;
}

.leave.leave_active_prev {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
  -webkit-transition: -webkit-transform 500ms linear;
  transition: -webkit-transform 500ms linear;
  transition: transform 500ms linear;
  transition: transform 500ms linear, -webkit-transform 500ms linear;
}

@media (min-width: 1680px) {
  .carousel {
    height: 200px;
  }
  .carousel_slide {
    padding: 10px 55px;
  }
}

@media (min-width: 1920px) {
  .carousel {
    height: 250px;
  }
  .carousel_slide {
    padding: 10px 65px;
  }
}
