.toggleBtn {
  position: absolute;
  left: 3px;
  top: 35px;
  width: 130px;
  height: 40px;
  border-radius: 5px;
  background-color: #307FF4;
  color: white;
  font-weight: 600;
  outline: none;
  cursor: pointer;
  padding: 1px 7px 2px;
  font-size: 13px;
  border: none;
  outline: none !important; }

.toggleBtn:disabled {
  background-color: #E5E5E5;
  opacity: 0.2;
  cursor: default;
  color: #333333; }

.mapboxgl-ctrl-zoom-in {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='29' height='29' viewBox='0 0 29 29' xmlns='http://www.w3.org/2000/svg' fill='%23333'%3E%3Cpath d='M14.5 8.5c-.75 0-1.5.75-1.5 1.5v3h-3c-.75 0-1.5.75-1.5 1.5S9.25 16 10 16h3v3c0 .75.75 1.5 1.5 1.5S16 19.75 16 19v-3h3c.75 0 1.5-.75 1.5-1.5S19.75 13 19 13h-3v-3c0-.75-.75-1.5-1.5-1.5z'/%3E%3C/svg%3E"); }

.mapboxgl-ctrl-zoom-out {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='29' height='29' viewBox='0 0 29 29' xmlns='http://www.w3.org/2000/svg' fill='%23333'%3E%3Cpath d='M10 13c-.75 0-1.5.75-1.5 1.5S9.25 16 10 16h9c.75 0 1.5-.75 1.5-1.5S19.75 13 19 13h-9z'/%3E%3C/svg%3E"); }

.mapboxgl-ctrl-compass {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='29' height='29' viewBox='0 0 29 29' xmlns='http://www.w3.org/2000/svg' fill='%23333'%3E%3Cpath d='M10.5 14l4-8 4 8h-8z'/%3E%3Cpath d='M10.5 16l4 8 4-8h-8z' fill='%23ccc'/%3E%3C/svg%3E"); }

section.create {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  position: relative;

  section.location {
    min-height: 600px;
    max-height: 715px;
    flex-grow: 1;
    margin-bottom: 48px;
    position: relative;
    background: white;
    canvas.mapboxgl-canvas {
      position: absolute;
      left: 0; } } }

