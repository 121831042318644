.PerspectiveView {
  background-color: #ffffff;
  width: 100%;
  color: #333333;
  font-family: Lato;
  font-size: 12px;
  transition: all 0.3s linear;
  width: 95vw;
}
.headLine {
  background-color: #ffffff;
  width: 100%;
  color: #333333;
  font-family: Lato;
  font-size: 12px;
  display: flex;
  align-items: center;
  border-bottom: 2px solid #e5e5e5;
  &_title {
    padding: 10px 0;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    text-align: center;
    flex-grow: 1;
  }
  &_button {
    cursor: pointer;
    background-color: transparent;
    padding: 10px 10px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    text-align: center;
    border: none;
    transition: color 300ms ease-in-out;
    fill: #333333;
    &:focus {
      outline: none;
    }
    &:hover {
      transition: color 300ms ease-in-out;
      fill: #307ff4;
    }
  }
  &_icon {
    color: inherit;
  }
}
.carousel {
  position: relative;
  height: calc(85vh);
  overflow: hidden;

  &_slide {
    padding: 10px 65px;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    text-align: center;
    z-index: 1;
    background-color: #ffffff;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
    & > div:nth-of-type(2) {
      // height: 100%;
      // width: 100%;
      overflow: visible;
      & > div {
        // justify-content: center;
        // transform: translate(0px, 400.5px) scale(1);
        // top: 50%;
        // left: 50%;
        // transform: translate(-50%, -50%) scale(1) !important;
      }
    }

    & img {
      max-width: 100%;
      max-height: 80vh;
      object-fit: contain;
    }
  }
}

.carousel_control_container {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  // align-items: center;
  height: 60px;
  padding-bottom: 20px;
}
.carousel_control {
  margin: 5px;
  align-items: center;
  display: flex;
  justify-content: center;
  border: 0;
  padding: 0;
  // width: 4%;
  width: 30px;
  overflow: hidden;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  cursor: pointer;
  fill: #333333;
  color: #ffffff;
  background-color: #333333;
}
.carousel_control:focus {
  outline: none;
}
.carousel_control:hover {
  fill: #307ff4;
  background-color: #307ff4;
}
.carousel_control__active {
  fill: #307ff4;
  background-color: #307ff4;
}
.carousel_control__prev,
.carousel_control__next {
  fill: #ffffff;
  background-color: #ffffff;
}

.leave {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

.enter_active.enter_next,
.enter_active.enter_prev {
  -webkit-transform: translateX(0);
  transform: translateX(0);
  -webkit-transition: -webkit-transform 500ms linear;
  transition: -webkit-transform 500ms linear;
  transition: transform 500ms linear;
  transition: transform 500ms linear, -webkit-transform 500ms linear;
}

.enter_next {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}

.enter_prev {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  -webkit-transition: -webkit-transform 500ms linear;
  transition: -webkit-transform 500ms linear;
  transition: transform 500ms linear;
  transition: transform 500ms linear, -webkit-transform 500ms linear;
}

.leave_active_next {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  -webkit-transition: -webkit-transform 500ms linear;
  transition: -webkit-transform 500ms linear;
  transition: transform 500ms linear;
  transition: transform 500ms linear, -webkit-transform 500ms linear;
}

.leave.leave_active_prev {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
  -webkit-transition: -webkit-transform 500ms linear;
  transition: -webkit-tran sform 500ms linear;

  transition: transform 500ms linear;
  transition: transform 500ms linear, -webkit-transform 500ms linear;
}

/* @media (min-width: 1680px) {
  .carousel {
    height: 200px;
  }
  .carousel_slide {
    padding: 10px 55px;
  }
}

@media (min-width: 1920px) {
  .carousel {
    height: 250px;
  }
  .carousel_slide {
    padding: 10px 65px;
  }
} */
