section.filters {
  margin: 20px 0 0 20px;
  .building, .room {
    background: white;
    text-align: left;
    padding: 15px 30px;
    margin-right: 10px;

    h3.title {
      color: #333333;
      font-family: Lato;
      font-size: 16px;
      font-weight: 900;
      line-height: 20px; }

    h5.name {
      color: #333333;
      font-family: Lato;
      font-size: 14px;
      font-weight: 700;
      line-height: 20px; }

    .floor-list {
      padding: 0;
      list-style: none;
      display: flex;
      justify-content: space-between;

      input {
        display: none; }

      label {
        width: 45px;
        height: 30px;
        font-size: 14px;
        color: #307FF4;
        background-color: #E7EEF9;
        border-radius: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer; }

      input:checked + label {
        color: #ffffff;
        background-color: #307FF4; }

      input:disabled + label {
        opacity: 0.5;
        cursor: default; } }

    .MuiSlider-marked {
      margin-bottom: 0; }
    .MuiSlider-root {
      margin-bottom: 5px;
      padding-top: 0;
      color: #307FF4; }

    .input-range {
      span {
        &.input-range__label--min, &.input-range__label--max {
          display: none; }

        &.input-range__label--value {
          top: unset;
          bottom: -1.6rem;
          color: #333333;
          font-family: Lato;
          font-size: 12px;
          font-weight: 900;
          line-height: 20px; } }

      &__track {
        transition: left 0.15s ease-out, width 0.15s ease-out; }

      .input-range__slider {
        background-color: #86ABFF;
        border: 1px solid rgb(134, 171, 255);
        width: 17px;
        height: 17px;

        &:active {
          transform: scale(1.1); }

        &-container {
          transition: left 0.15s ease-out; } }

      .input-range__track {
        height: 3px; }
      .input-range__track--active {
        // height: 3px
        background: #86ABFF; }
      &--disabled {
        opacity: 0.3; } }

    .textInput {
      margin-bottom: 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #00A2E8;
      .textInput_fix {
        display: flex;
        flex-direction: column;
        justify-content: flex-end; }
      .inputContainer {
        position: relative; }
      .inputSuffix, .inputSuffixPercent, .inputSuffixFT {
        position: absolute;
        top: 5px;
        right: -57px;
        color: #00A2E8;
        font-family: Lato;
        font-size: 15px;
        line-height: 20px;
        font-weight: 400;
        user-select: none; }
      .MeetingRooms {
        padding-right: 42px;
        padding-left: 10px;
        width: 110px; }
      .inputFT {
        padding-right: 23px; }
      .inputRight {
        margin-left: auto; }
      .inputPercent {
        padding-right: 23px; }
      .inputSuffixPercent {
        right: 11px; }
      .inputSuffixFT {
        right: 11px; }
      label {
        margin: 0 0 5px 0;
        color: #333333;
        font-family: Lato;
        font-size: 14px;
        font-weight: 700;
        line-height: 20px; }
      input, select {
        display: block;
        width: 95px;
        padding: 5px 10px;
        color: #00A2E8;
        font-family: Lato;
        font-weight: 700;
        font-size: 15px;
        line-height: 20px;
        text-align: right;
        background-color: #F4F7FC;
        border: none; }
      select {
        color: #333333;
        font-weight: 400; } } }

  .button {
    background: white;
    margin-bottom: 10px;
    button.filter {
      background-color: #86ABFF;
      border: none;
      outline: none;
      color: white;
      border-radius: 5px;
      border: 1px solid transparent;
      transition: all 0.3s linear;

      &:hover {
        cursor: pointer;
        border: 1px solid #E5E5E5;
        transition: all 0.3s linear; } } } }
