.tabs {
    list-style: none;
    display: flex;
    align-items: stretch;
    padding: 0;
    margin-top: 20px;
    margin-bottom: 10px;
    width: 98.5%;
    height: 80px;
    >li {
        flex-basis: 260px;
        flex-grow: 1; } }
