.container {
  // min-width: 1250px
  @media screen and (min-width: 1440px) {
    // max-width: 1250px !important
    max-width: 90% !important; } }

section.projects {
  width: 100%;

  .link {
    color: #307FF4;
    font-size: 1.5rem;
    font-style: italic;
    font-weight: 500; }

  .wrapper {
    height: 216px;
    padding: 0 8px;
    color: inherit;
    @media screen and (min-width: 1900px) {
      -ms-flex: 0 0 25%;
      flex: 0 0 25%;
      max-width: 25%!important; }
    &:hover {
      text-decoration: none; }

    a {
      color: inherit; }

    .project {
      width: 410px;
      background: white;
      z-index: 0;
      border: 1px solid white;
      transition: all 0.3s linear;

      &:hover {
        cursor: pointer;
        border: 2px solid #307FF4;
        transition: all 0.3s linear; }

      .info {
        width: 58%;
        position: relative;
        z-index: 0;

        h5 {
          font-size: 18px;
          white-space: nowrap;
          width: 90%;
          text-overflow: ellipsis;
          overflow: hidden; }

        p {
          font-size: 14px; }

        .location {
          font-size: 14px;
          .icon {
            height: 15px;
            width: 11px;
            display: block;
            margin-right: 10px;
            fill: #333333; } }

        span.more {
          width: 20px;
          height: 30px;
          text-align: center;
          position: absolute;
          top: 5px;
          right: 0;
          cursor: pointer;
          z-index: 999999;
          .icon {
            fill: #333333; }
          &:hover .icon {
            fill: #307FF4; }

          .more-controls {
            width: 210px;
            position: relative;
            background: white;
            text-align: left;
            border: 1px solid gainsboro;
            z-index: 1;

            div {
              height: 40px;
              display: flex;
              align-items: center;
              .icon {
                fill: #333333; }

              &:hover {
                background-color: #333333;
                color: white;
                .icon {
                  fill: #ffffff; } }

              svg {
                width: 30px;
                height: 30px;
                margin: 0 10px;
                fill: #ffffff;
                .icon {
                  fill: #333333; } }

              &:nth-child(2) {
                border-top: 1px solid rgba(34,35,42,0.13);
                border-bottom: 1px solid rgba(34,35,42,0.13); } } } } }

      .logo {
        width: 40%;
        border-left: 1px solid #333333;
        display: flex;
        align-items: center;
        justify-content: center;

        .img {
          // background-image: url("../../../assets/img/hilton.png")
          width: 100px;
          height: 100px;
          background-size: contain;
          background-repeat: no-repeat;
          display: flex;
          background-position: center center;
          margin: 0 auto; } }

      .gray {
        color: #333333; } } }

  .add {
    padding: 0 8px;
    text-align: left;
    @media screen and (min-width: 1900px) {
      -ms-flex: 0 0 25%;
      flex: 0 0 25%;
      max-width: 25%!important; }

    img {
      border: 2px solid #E5E5E5;
      transition: all 0.3s linear;
      height: 216px;
      width: 410px;

      &:hover {
        transition: all 0.3s linear;
        border: 2px solid #307FF4;
        cursor: pointer; } } } }
