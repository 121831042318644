.login {
  display: flex;
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #333333;
  .leftSide {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .logoLink {
      margin-bottom: 72px;
    }
    form {
      text-align: left;
      h2 {
        font-size: 32px;
        line-height: 38px;
        text-align: left;
        margin-bottom: 16px;
      }
      .welcome {
        margin-bottom: 29px;
        color: #828282;
      }
      label {
        display: block;
        margin-bottom: 6px;
        font-size: 10px;
        line-height: 12px;
      }
      input {
        width: 372px;
        height: 38px;
        margin-bottom: 26px;
        border: 1px solid #e0e0e0;
        border-radius: 4px;

        color: #828282;
        padding: 0 16px;
      }
      .forgotPasswordContainer {
        display: flex;
        justify-content: space-between;
        margin-bottom: 42px;

        label {
          font-size: 14px;
          line-height: 17px;
          //   color: #333333;
          cursor: pointer;
          input {
            box-sizing: border-box;
            margin-bottom: 0;
            width: 16px;
            height: 16px;
            border: 1px solid #e0e0e0;
            border-radius: 4px;
            margin-right: 7px;
          }
        }
        .logoLink {
          margin-bottom: 0;
          color: #2e53da;
        }
      }
      .buttonSignIn,
      .buttonSignInWithGoogle {
        display: block;
        width: 372px;
        height: 38px;
        background: #2e53da;
        border-radius: 4px;
        margin-bottom: 16px;
        font-size: 14px;
        line-height: 38px;
        text-align: center;
        color: #ffffff;
        &:hover {
          text-decoration: none;
        }
      }
      .buttonSignInWithGoogle {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 25px;
        background-color: transparent;
        font-weight: 600;
        color: #333333;
        border: 1px solid #828282;
        border-radius: 4px;
        img {
          margin-right: 12px;
        }
      }
      .signUp {
        display: flex;
        justify-content: center;
        p {
          margin-bottom: 0;
          margin-right: 8px;
          font-size: 12px;
          line-height: 14px;
          color: #828282;
        }
        .buttonSignUn {
          font-size: 12px;
          line-height: 14px;
          color: #2e53da;
        }
      }
    }
  }
  .rightSide {
    width: 50%;
    background-image: url("../../../assets/img/construction.png");
    background-repeat: no-repeat;
    background-position: right;
    background-size: contain;
    height: 100vh;
  }
}
