.items {
  background: #F7F7F7;
  background-size: contain;

  .item {
    background: white;
    border: 1px solid transparent;
    transition: 0.3s all linear;
    width: 200px;
    height: 200px;
    padding: 15px;

    &:hover {
      cursor: pointer;
      border: 2px solid #307FF4;
      transition: 0.3s all linear; }

    & img {
      object-fit: contain;
      height: inherit; } }

  .disabled {
    opacity: 0.5;
    pointer-events: none;
    user-select: none; }

  .coming-soon {
    position: relative;
    &::before {
      content: 'Coming soon';
      display: block;
      text-align: center;
      font-family: sans-serif;
      border: 1px solid red;
      position: absolute;
      top: 50px;
      left: 20px;
      transform: rotate(-45deg);
      z-index: 1;
      color: red;
      font-weight: bold;
      padding: 5px; } } }

.text {
  text-align: left;

  h5.title {
    color: #BDBFC6;
    font-size: 14px; }

  h2 {
    font-weight: 600;
    font-size: 26px; }

  h4.title {
    font-size: 16px;
    font-weight: 500;
    color: #979797; }

  input {
    width: 100%;
    border: none;
    border-bottom: 1px solid #BDBFC6;
    outline: none;

    &.error {
      border: 1px solid red;
      text-indent: 10px; } } }

.controls {
  position: absolute;
  right: 30px;
  bottom: 35px;

  button {
    outline: none;
    border: none;
    cursor: pointer;

    &.next {
      width: 130px;
      height: 40px;
      border-radius: 3px;
      background-color: #307FF4;
      color: white;
      font-weight: 600;
      text-transform: capitalize;

      &:after {
        content: '';
        background-image: url("../../../../assets/img/icons/next.png");
        width: 10px;
        height: 10px;
        background-repeat: no-repeat;
        display: inline-block;
        position: relative;
        background-size: contain;
        left: 5px; } }

    &.prev {
      margin-right: 20px;
      height: 40px;
      width: 80px;
      color: #9A9CA5;
      background: transparent;
      font-weight: 600;
      text-transform: capitalize;

      &:before {
        content: '';
        background-image: url("../../../../assets/img/icons/prev.png");
        width: 10px;
        height: 10px;
        background-repeat: no-repeat;
        display: inline-block;
        position: relative;
        background-size: contain;
        right: 5px; } } } }

.brand {
  overflow-y: auto;
  max-height: inherit; }

.validateMessage {
  color: red;
  font-size: 20px; }
