.Map3d {
  //   min-height: 250px;
  margin: 0;
  margin-top: 20px;
  padding: 0;
  flex-grow: 1;
  position: relative;
  background: white;
  border: none;
  transition: all 0.3s linear;
  .columnContainer {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
    .height,
    .floors,
    .parking,
    .far {
      width: 24.5%;
      background-color: #ffffff;
      color: #333333;
      font-family: Lato;
      font-size: 14px;
      padding: 20px;
      display: flex;
      justify-content: center;
      @media screen and (min-width: 1750px) {
        justify-content: space-between;
      }
    }

    .bold {
      font-weight: 700;
      margin-left: 3px;
    }
  }
  &:focus {
    outline: none;
  }
  .toggleBtn {
    position: absolute;
    left: 3px;
    top: 5px;
    width: 130px;
    height: 40px;
    border-radius: 5px;
    background-color: #307ff4;
    color: white;
    font-weight: 600;
    outline: none;
    cursor: pointer;
    padding: 1px 7px 2px;
    font-size: 13px;
    border: none;
    outline: none !important;
  }

  .toggleBtn:disabled {
    background-color: #e5e5e5;
    opacity: 0.2;
    cursor: default;
    color: #333333;
  }
}

.MapInnerContainer {
  height: calc(100vh - 300px);
  width: 100%;
  position: relative;
}
.gray {
  color: #333333;
}

///////////Carousel
.carousel {
  position: relative;
  // height: 190px;
  height: calc(100vh - 430px);
  overflow: hidden;

  &_title {
    padding: 10px 0;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    text-align: center;
    border-bottom: 2px solid #e5e5e5;
  }
  &_slide {
    // padding: 10px 45px;
    padding: 0;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    text-align: center;
    z-index: 1;
    background-color: #ffffff;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
    img {
      padding: 10px 45px;
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
    }
  }
}

.ArrowLeft,
.ArrowRight,
.Icon,
.IconNumbers {
  max-width: 100%;
  max-height: 100%;
}
.IconNumbers {
  font-size: 16px;
  font-weight: 700;
  line-height: 1.3;
}

.carousel_control_container {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.carousel_control {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 5px;
  border: 0;
  padding: 0;
  // width: 4%;
  width: 30px;
  overflow: hidden;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  cursor: pointer;
  fill: #333333;
  color: #ffffff;
  background-color: #333333;
  height: 30px;
}
.carousel_control:focus {
  outline: none;
}
.carousel_control:hover {
  fill: #307ff4;
  background-color: #307ff4;
}
.carousel_control__active {
  fill: #307ff4;
  background-color: #307ff4;
}
.carousel_control__prev,
.carousel_control__next {
  fill: #ffffff;
  background-color: #ffffff;
}

.leave {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

.enter_active.enter_next,
.enter_active.enter_prev {
  -webkit-transform: translateX(0);
  transform: translateX(0);
  -webkit-transition: -webkit-transform 500ms linear;
  transition: -webkit-transform 500ms linear;
  transition: transform 500ms linear;
  transition: transform 500ms linear, -webkit-transform 500ms linear;
}

.enter_next {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}

.enter_prev {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  -webkit-transition: -webkit-transform 500ms linear;
  transition: -webkit-transform 500ms linear;
  transition: transform 500ms linear;
  transition: transform 500ms linear, -webkit-transform 500ms linear;
}

.leave_active_next {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  -webkit-transition: -webkit-transform 500ms linear;
  transition: -webkit-transform 500ms linear;
  transition: transform 500ms linear;
  transition: transform 500ms linear, -webkit-transform 500ms linear;
}

.leave.leave_active_prev {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
  -webkit-transition: -webkit-transform 500ms linear;
  transition: -webkit-transform 500ms linear;
  transition: transform 500ms linear;
  transition: transform 500ms linear, -webkit-transform 500ms linear;
}

.ViewerModal {
  width: 90vw;
  height: 90vh;
}

@media (min-width: 1680px) {
  .carousel {
    // height: 200px;
    height: calc(100vh - 430px);
    &_slide {
      // padding: 10px 55px;
      padding: 0;
    }
  }
}

@media (min-width: 1920px) {
  .carousel {
    // height: 250px;
    height: calc(100vh - 430px);
    &_slide {
      // padding: 10px 65px;
      padding: 0;
    }
  }
}

.mapParcel {
  height: 250px;
  position: relative;
}
.mapParcel canvas {
  position: absolute;
  left: 0;
  width: 100%;
}
