.investment {
  margin: 20px 0 0 20px;
  .blockPanel {
    background: white;
    text-align: left;
    padding: 20px;
    position: relative;
    margin-bottom: 5px;
    margin-right: 10px;

    h3.title {
      color: #333333;
      font-family: Lato;
      font-size: 16px;
      font-weight: 900;
      line-height: 20px;
      margin-bottom: 25px; }

    h5.name {
      color: #333333;
      font-family: Lato;
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;
      text-transform: capitalize; }

    .radioBlended {
      margin-bottom: 13px;
      &>div {
        margin-left: 10px; }

      label {
        margin: 0;
        height: 24px;
        width: 95px;
        font-size: 12px;
        font-weight: 700;
        line-height: 24px;
        text-align: center;
        background: #E5E5E5;
        cursor: pointer;
        text-transform: capitalize;
        transition: all 200ms ease-in-out;

        &:first-of-type {
          border-top-left-radius: 20px;
          border-bottom-left-radius: 20px;
          margin-right: 5px; }

        &:last-of-type {
          border-top-right-radius: 20px;
          border-bottom-right-radius: 20px; } }

      input {
        display: none;

        &:checked + label {
          background: #307FF4;
          color: #FFFFFF; } } }

    label {
      margin: 0 0 5px 0;
      color: #333333;
      font-family: Lato;
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;
      text-transform: capitalize;
      position: relative; }

    .textInput {
      display: flex;
      justify-content: space-between;
      .inputContainer {
        position: relative; }
      input.inputPercent {
        padding-right: 22px;
        width: 95px; }
      input.inputPerKey {
        padding-right: 43px; }
      label.inputSuffixPercent, label.inputSuffixMonths, label.inputSuffixKey {
        position: absolute;
        top: 5px;
        right: 10px;
        color: #00A2E8;
        font-family: Lato;
        font-size: 15px;
        line-height: 20px;
        font-weight: 400;
        user-select: none; }
      label.inputSuffixMonths {
        right: 10px;
        text-transform: lowercase; }
      label.inputSuffixKey {
        right: 10px; }

      input {
        margin: 0 0 15px 0;
        display: block;
        width: 160px;
        padding: 5px 22px 5px 10px;
        color: #00A2E8;
        font-family: Lato;
        font-size: 15px;
        line-height: 20px;
        text-align: right;
        background-color: #F4F7FC;
        border: none;
        font-weight: 700; }

      >span {
        width: 115px;
        color: #333333;
        font-family: Lato;
        font-size: 12px;
        font-weight: 700;
        line-height: 30px; }

      .months {
        padding-right: 63px; }
      .months::-webkit-outer-spin-button,
      .months::-webkit-inner-spin-button {
        -webkit-appearance: none;
        color: red;
        margin: 0; } } }

  .blockPanel:nth-child(2) {
    .textInput {
      input.inputPercent {
        width: 160px; } } } }

.submit {
  border: none;
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: transparent;
  cursor: pointer;
  padding: 0;
  font-size: 5px;
  &:focus {
    outline: none; }
  .roundArrow {
    fill: rgb(68, 77, 111);
    transition: all 200ms ease-in-out;
    &:hover, &:focus {
      fill: #307FF4;
      transform: rotate(25deg); }
    &:active {
      fill: #ff0000; } } }
