//.linkStyle{
//    height: 40px;
//    display: flex;
//    align-items: center;
//    transition: all 200ms ease-in-out;
//    color:black;
//    text-decoration: none;
//
//}
//.linkStyle:hover{
//    background-color: #333333;
//    color: white;
//    .icon {
//        fill: #ffffff;
//    }
//    .QuestionIcon {
//        color: #ffffff;
//        border: 1px solid #ffffff;
//    }
//}
.linkStyle {
  height: 40px !important;
  display: flex;
  align-items: center;
  transition: all 200ms ease-in-out;
  color: #333333;
  text-decoration: none;

  &:hover {
    text-decoration: none;
    background-color: #333333;
    color: white;

    .icon {
      fill: #ffffff;
    }

    .QuestionIcon {
      color: #ffffff;
      border: 1px solid #ffffff;
    }
  }
  svg {
    width: 24px;
    height: 24px;
    margin: 0 10px;
  }
  // fill: #ffffff
  .icon {
    fill: #333333;
  }

  .QuestionIcon {
    display: inline-block;
    width: 24px;
    height: 24px;
    margin: 0 10px;
    border-radius: 50%;
    border: 1px solid #333333;
    color: #333333;
    padding-right: 0;
    line-height: 20px;
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    cursor: pointer;
    &:not(:last-child) {
      border-bottom: 1px solid rgba(34, 35, 42, 0.13);
    }
  }
}
