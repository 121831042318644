.buildingProgram {
  .MuiSlider-marked {
    margin-bottom: 0; }
  .MuiSlider-root {
    margin-bottom: 5px;
    padding-top: 0;
    color: #307FF4; }

  .textInput {
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #00A2E8;
    .textInput_fix {
      display: flex;
      flex-direction: column;
      justify-content: flex-end; }
    .inputContainer {
      position: relative; }
    .inputSuffix, .inputSuffixPercent, .inputSuffixFT {
      position: absolute;
      top: 5px;
      right: -57px;
      color: #00A2E8;
      font-family: Lato;
      font-size: 15px;
      line-height: 20px;
      font-weight: 400;
      user-select: none; }
    .MeetingRooms {
      padding-right: 42px;
      padding-left: 10px;
      width: 110px; }
    .inputFT {
      padding-right: 23px; }
    .inputRight {
      margin-left: auto; }
    .inputPercent {
      padding-right: 23px; }
    .inputSuffixPercent {
      right: 12px; }
    .inputSuffixFT {
      right: 12px; }
    label {
      margin: 0 0 5px 0;
      color: #333333;
      font-family: Lato;
      font-size: 14px;
      font-weight: 700;
      line-height: 20px; }
    input, select {
      display: block;
      width: 95px;
      padding: 5px 10px;
      color: #00A2E8;
      font-family: Lato;
      font-weight: 700;
      font-size: 15px;
      line-height: 20px;
      text-align: right;
      background-color: #F4F7FC;
      border: none; }
    select {
      color: #333333;
      font-weight: 400; } } }
