.text {
  text-align: left;
  display: flex;
  flex-direction: column;
  // justify-content: space-between

  h5.title {
    color: #BDBFC6;
    font-size: 14px; }

  h2 {
    font-weight: 400;
    font-size: 26px; }

  h4.title {
    font-size: 16px;
    font-weight: bold; }

  input {
    width: 100%;
    border: none;
    border-bottom: 1px solid #BDBFC6;
    outline: none; }

  div.geocoder.error input {
    border-bottom: 1px solid red; }

  .country input {
    text-transform: uppercase; }

  .street, .city, .additional, .state, .zip, .country {
    display: flex;
    align-items: flex-end;
    margin: 10px 0;

    span.title {
      color: #979797;
      font-weight: 600;
      min-height: 24px; }

    input {
      margin-left: 15px; } }

  .zip span {
    white-space: nowrap; } }

.controls {
  margin-bottom: 11px;
  margin-right: 6px;
  text-align: right;

  button {
    outline: none;
    border: none;
    cursor: pointer;

    &.next {
      width: 130px;
      height: 40px;
      border-radius: 3px;
      background-color: #307FF4;
      color: white;
      font-weight: 600;

      &:after {
        content: '';
        background-image: url("../../../../assets/img/icons/next.png");
        width: 10px;
        height: 10px;
        background-repeat: no-repeat;
        display: inline-block;
        position: relative;
        background-size: contain;
        left: 5px; }

      &:disabled {
        opacity: 0.5; } }

    &.prev {
      margin-right: 20px;
      height: 40px;
      width: 80px;
      color: #9A9CA5;
      background: transparent;
      font-weight: 600;

      &:before {
        content: '';
        background-image: url("../../../../assets/img/icons/prev.png");
        width: 10px;
        height: 10px;
        background-repeat: no-repeat;
        display: inline-block;
        position: relative;
        background-size: contain;
        right: 5px; } } } }
