.tooltipLeftBottom {
  display: inline-block;
  position: relative;
  .tooltipIcon {
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid #333333;
    color: #333333;
    padding-right: 0;
    line-height: 16px;
    font-size: 14px;
    font-weight: 700;
    text-align: center;
    cursor: pointer;
    &:hover {
      text-decoration: none;
      & + .tooltipContent {
        z-index: 1;
        opacity: 1;
        transition: all 300ms ease-in-out; } } }
  .tooltipContent {
    opacity: 0;
    position: absolute;
    z-index: -1;
    background-color: #ffffff;
    border: 1px solid #333333;
    text-align: left;
    top: 20px;
    left: -200px;
    width: 200px;
    padding: 10px;
    text-transform: none;
    border-radius: 3px;
    font-weight: 400;
    .tooltipTitle {
      text-transform: capitalize;
      font-weight: 700; } } }
