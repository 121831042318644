.table {
    margin: 0 0px 10px 0;
    color: #333333;
    font-family: Lato;
    font-size: 14px;
    font-weight: bold;
    line-height: 20px;
    text-align: right;
    width: 98.5%;
    // min-width: 780px
    background-color: #ffffff;
    thead {
        color: #333333;
        background-color: #ffffff;
        font-weight: 700;
        th {
            padding-right: 20px;
            background-color: #ffffff; }
        & th:first-child {
            width: 260px;
            font-size: 16px;
            font-weight: 900;
            color: #333333;
            height: 40px;
            text-align: left;
            padding-left: 20px; } }
    tfoot {
        font-weight: 900;
        tr {
            background-color: #ffffff;
            &:nth-child(even) {
                background-color: #F4F7FC;
                font-weight: 400;
                td {
                    height: 28px; } }

            td {
                height: 39px;
                padding-right: 20px; }
            td:first-child {
                width: 260px;
                text-align: left;
                padding-left: 20px;
                border-right: 1px solid #333333; } } }
    tbody {
        tr {
            font-weight: 400;
            background-color: #ffffff;
            &:first-child {
                border-top: 1px solid #333333;
                height: 15px; }
            &:nth-child(even) {
                background-color: #F4F7FC; }
            &:last-child {
                background-color: #ffffff;
                height: 11px; }
            td {
                padding: 4px 0;
                padding-right: 20px; }
            td:first-child {
                width: 260px;
                text-align: left;
                padding-left: 20px;
                border-right: 1px solid #333333; } } } }
