.buttonContainerSite {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  .Btn {
    margin-right: 20px;
    width: 215px;
    border-radius: 3px;
    background-color: #307FF4;
    color: white;
    font-weight: 700;
    outline: none;
    cursor: pointer;
    padding: 2px;
    font-size: 12px;
    border: none;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .buttonText {
      flex-grow: 1; } }


  .Btn:disabled {
    opacity: 0.5;
    cursor: default;
    background-color: #E5E5E5;
    color: #333333; }

  .polygonEdit {
    stroke: #ffffff; }
  .polygonEdit:disabled {
    stroke: #333333; }
  .radio {
    margin-right: 20px;
    display: flex;
    // &>div
    //   margin-left: 10px

    label {
      margin: 0;
      height: 34px;
      width: 103px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 12px;
      font-weight: 700;
      line-height: 13px;
      // text-align: center
      background: #E5E5E5;
      cursor: pointer;
      text-transform: capitalize;
      transition: all 200ms ease-in-out;
      stroke: #333333;
      padding: 7px;
      .buttonText {
        display: flex;
        width: 60px; }

      &:first-of-type {
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        margin-right: 9px;
        text-align: right; }

      &:last-of-type {
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px; } }


    input {
      display: none;

      &:checked + label {
        background: #307FF4;
        color: #FFFFFF;
        stroke: #ffffff; }
      &:disabled + label {
        opacity: 0.5;
        cursor: default;
        background-color: #E5E5E5;
        color: #333333;
        stroke: #333333; } } } }

.undoRedoBtnContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 215px;
  margin-bottom: 20px;
  .undoBtn, .redoBtn {
    padding: 10px;
    width: 103px;
    height: 34px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #307FF4;
    color: white;
    font-weight: 700;
    outline: none;
    cursor: pointer;
    font-size: 12px;
    border: none; }

  .undoBtn:disabled, .redoBtn:disabled {
    background-color: #E5E5E5;
    opacity: 0.5;
    cursor: default;
    color: #333333; }

  // .undoBtn
  //   border-top-left-radius: 20px
  //   border-bottom-left-radius: 20px
  // .redoBtn
  //   border-top-right-radius: 20px
 }  //   border-bottom-right-radius: 20px



.text {
  text-align: left;
  .text-left>span {
    width: 450px; }

  h5.title {
    color: #BDBFC6;
    font-size: 14px; }
  h2 {
    font-weight: 600;
    font-size: 26px; }

  h4.titleFirst {
    font-size: 16px;
    font-weight: 500;
    color: #979797;
    margin-bottom: 48px; }
  h4.title {
    font-size: 16px;
    font-weight: 500;
    color: #979797;
    margin-bottom: 8px; }
  p.test {
    font-size: 10px;
    span {
      color: #E5E5E5; } }

  input {
    width: 100%;
    border: none;
    border-bottom: 1px solid #BDBFC6;
    outline: none;

    &.error {
      border: 1px solid red;
      text-indent: 10px; } } }

.controls {
  position: absolute;
  right: 30px;
  bottom: 35px;

  button {
    outline: none;
    border: none;
    cursor: pointer;

    &.next {
      width: 130px;
      height: 40px;
      border-radius: 3px;
      background-color: #307FF4;
      color: white;
      font-weight: 600;

      &:after {
        content: '';
        background-image: url("../../../../assets/img/icons/next.png");
        width: 10px;
        height: 10px;
        background-repeat: no-repeat;
        display: inline-block;
        position: relative;
        background-size: contain;
        left: 5px; } }

    &.prev {
      margin-right: 20px;
      height: 40px;
      width: 80px;
      color: #9A9CA5;
      background: transparent;
      font-weight: 600;

      &:before {
        content: '';
        background-image: url("../../../../assets/img/icons/prev.png");
        width: 10px;
        height: 10px;
        background-repeat: no-repeat;
        display: inline-block;
        position: relative;
        background-size: contain;
        right: 5px; } } } }
