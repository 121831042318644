section.not-found {
  background-image: url("../../assets/img/404-bg.jpg");
  text-align: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;

  h1 {
    color: white;
    padding-top: 0px;

    @media screen and (min-width: 768px) {
      font-size: 60px; }

    @media screen and (min-width: 1200px) {
      font-size: 90px; } }

  img {
    width: 75%;

    @media screen and (min-width: 768px) {
      width: 50%; } } }
