.workspace {
  min-height: calc(100vh - 60px);
  width: 100%;
  display: flex;
  justify-content: center;
  background-size: cover;
  background-repeat: no-repeat;

  font-family: 'Lato', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: #333333; }
.colon {
  padding: 0;
  width: 770px; }
.tablesContainer {
  overflow-y: scroll;
  // height: calc(100vh - 146px)
  position: relative;
  &:after {
    display: block;
    height: 0%;
    content: ""; }
  &::-webkit-scrollbar {
    width: 0px; } }

progress.bar {
  margin-top: 140px;
  margin-bottom: 50px;
  background-color: #333333;
  border: 0;
  height: 18px;
  border-radius: 9px;
  width: 300px;
  &::-webkit-progress-bar {
    background-color: #333333;
    border: 0;
    height: 18px;
    border-radius: 9px; }
  &::-webkit-progress-value {
    background-color: #307FF4;
    border: 0;
    height: 18px;
    border-radius: 9px; }
  &::-moz-progress-bar {
    background-color: #307FF4;
    border: 0;
    height: 18px;
    border-radius: 9px; } }

p.generating, p.ready, p.meantime, p.adjusting, p.designs {
  font-family: 'Lato', sans-serif;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0.5px;
  line-height: 20px;
  color: #333333;
  margin-bottom: 0;
  width: 100%;
  box-sizing: border-box; }
span.courtyard {
  color: #307FF4; }
p.ready {
  margin-bottom: 40px; }
p.meantime {
  font-style: italic; }
p.adjusting {
  font-weight: 400;
  margin-bottom: 30px; }
p.designs {
  margin-top: 170px;
  margin-bottom: 20px; }

button.ViewResults {
  padding: 14px 50px;
  border: none;
  background-color: #307FF4;
  color: #ffffff;
  font-size: 18px;
  font-weight: 700;
  cursor: pointer;
  margin-bottom: 30px;
  &:focus {
    outline: none; } }
